<template>
  <div class="app-container">
    <!-- 操作工作栏 -->
    <a-row :gutter="10" class="mb8">
      <a-col>
        <a-button type="primary" size="default" @click="handleExportHtml">導出 HTML</a-button>
        <a-button type="primary" size="default" @click="handleExportWord">導出 Word</a-button>
        <a-button type="primary" size="default" @click="handleExportMarkdown">導出 Markdown</a-button>
      </a-col>
    </a-row>

    <!-- 展示文档 -->
    <div v-loading="loading" :style="'height:'+ height">
      <i-frame :src="src" />
    </div>
  </div>
</template>
<script>
import { exportHtml, exportWord, exportMarkdown} from "@/api/dbdoc";
import iFrame from "@/components/iFrame/index";
import download from "@/plugins/download";

export default {
  name: "DBDoc",
  components: { iFrame },
  data() {
    return {
      height: document.documentElement.clientHeight - 94.5 + "px;",
      loading: true,
      src: "undefined",
    };
  },
  mounted: function() {
    setTimeout(() => {
      this.loading = false;
    }, 230);
    const that = this;
    window.onresize = function temp() {
      that.height = document.documentElement.clientHeight - 94.5 + "px;";
    };
  },
  created() {
    // 加载 Html，进行预览
    exportHtml().then(response => {
      let blob = new Blob([response], {type : 'text/html'});
      this.src = window.URL.createObjectURL(blob);
    })
  },
  methods: {
    /** 处理導出 HTML */
    handleExportHtml() {
      exportHtml().then(response => {
        download.html(response, '数据库文档.html');
      })
    },
    /** 处理導出 Word */
    handleExportWord() {
      exportWord().then(response => {
        download.word(response, '数据库文档.doc');
      })
    },
    /** 处理導出 Markdown */
    handleExportMarkdown() {
      exportMarkdown().then(response => {
        download.markdown(response, '数据库文档.md');
      })
    }
  }
};
</script>
<style scoped>
.app-container {
    padding: 20px;
    background: #fff;
}
.mb8 {
    margin-bottom: 8px;
}
.ant-btn+.ant-btn {
    margin-left: 10px;
}
</style>
